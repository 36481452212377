"use strict";

import {Vec3, Mat3} from './matrix';
import {
    make_elements,
    remove_children,
    fold,
    RangeIterator,
    getReqFullscreen,
    getExitFullscreen,
    remove_node,
    wait,
} from './utils';

declare namespace renderModule {
    interface Renderers {
        render8(pmin : number, pmax : number, pscl : number, n : number) : void;
        render8inverted(pmin : number, pmax : number, pscl : number, n : number) : void;
        render16signed(pmin : number, pmax : number, pscl : number, n : number) : void;
        render16unsigned(pmin : number, pmax : number, pscl : number, n : number) : void;
        render16inverted_signed(pmin : number, pmax : number, pscl : number, n : number) : void;
        render16inverted_unsigned(pmin : number, pmax : number, pscl : number, n : number) : void;
        render8rgb(pmin : number, pmax : number, pscl : number, n : number) : void;
        unsupported(pmin : number, pmax : number, pscl : number, n : number) : void;
    }

    function asm(stdlib : any, foreign : any, heap : ArrayBuffer) : Renderers;
    function nextValidHeapSize(size : number) : number;
}

declare namespace dicomParser {
    function parseDicom(x : any) : any;
    function isEncapsulated(x : HTMLElement, y : any) : boolean;
    function readTag(x : any) : any;
    function createJPEGBasicOffsetTable(x : any, y : any) : any;
    function readEncapsulatedImageFrame(x : any, y : any, z : any, w? : any) : any;
    function readEncapsulatedPixelDataFromFragments(x : any, y : any, z : any) : any;
}

declare namespace pako {
    function inflateRaw(data : ArrayBuffer, opts : any) : Uint8Array;
    function inflateRaw(data : ArrayBuffer) : Uint8Array;
    function deflateRaw(data : ArrayBuffer, opts : any) : Uint8Array;
    function deflateRaw(data : ArrayBuffer) : Uint8Array;
}

declare namespace jpeg {
    namespace lossless {
        class Decoder {
            decode(data : ArrayBuffer, f : number, l : number, w : number) : Uint8Array;
        }
    }
}

declare interface Tile {
    items: Int16Array | Uint16Array | Int8Array | Uint8Array;
}

declare class JpxImage {
    parse(data: Uint8Array) : void;
    width: number;
    height: number;
    componentsCount: number;
    tiles: Tile[];
}

declare namespace webkitURL {
    function createObjectURL(blob : any) : any;
    function revokeObjectURL(blob : any) : void;
}
    
function sleep(t : number) {
    return new Promise((r) => setTimeout(r, t));
}

//----------------------------------------------------------------------------
// JSON DOM Component

const dicom_viewer_ui = {
    title: {elem:'div', className:'title'},
    title_text:{elem:'text', parent:'title', text:''},
    control_panel: {elem: 'div', className: 'control-panel'},
    image_slider_lead: {elem: 'nobr', parent: 'control_panel', className: 'control-container',
        children: [{elem:'div', children: [{elem: 'text', text: 'image:  '}], className: 'control-text'}]},
    image_slider_set: {elem: 'input', type: 'range', value:50, min:0, max:100, step:1,
        parent: 'image_slider_lead', className: 'control-range'},
    image_slider_box: {elem:'div', className: 'control-text', parent: 'image_slider_lead'},
    image_slider_trail: {elem: 'text', text: ' 1/1 ', parent: 'image_slider_box'},
    close_control: {elem: 'input', type: 'button', className: 'control',
        value: '\u2716', parent: 'control_panel'},
    pan_control: {elem: 'input', type: 'button', className: 'control',
        value: 'pan', parent: 'control_panel'},
    zoom_control: {elem: 'input', type: 'button', className: 'control',
        value: 'zoom', parent: 'control_panel'},
    rotate_control: {elem: 'input', type: 'button', className: 'control',
        value: 'rotate', parent: 'control_panel'},
    params_control: {elem: 'input', type: 'button', className: 'control',
        value: 'params', parent: 'control_panel'},
    //image_set_control: {elem: 'input', type: 'button', className: 'control',
    //    value: 'image 1/1', parent: 'control_panel'},
    //
    window_nobr: {elem: 'nobr', children: [{elem:'div',
        style: 'display: flex !important; flex-flow: row; align-items: center; margin-bottom: 0.8rem;',
        children: [{elem: 'text', text: 'Window: '}]}], parent: 'control_panel',
        style: 'display: flex !important; flex-flow: row; align-items: stretch;'},
    window_control: {elem: 'select', className: 'control', id:'window', 
        children: [
            {elem: 'option', value: 'user',
                children: [{elem: 'text', text: 'user defined'}]},
            {elem: 'option', value: 'abdomen',
                children: [{elem: 'text', text: 'CT - Abdomen'}]},
            {elem: 'option', value: 'pulmonary',
                children: [{elem: 'text', text: 'CT - Pulmonary'}]},
            {elem: 'option', value: 'brain',
                children: [{elem: 'text', text: 'CT - Brain'}]},
            {elem: 'option', value: 'bone',
                children: [{elem: 'text', text: 'CT - Bone'}]},
        ], parent: 'window_nobr'},
    reset_control: {elem: 'input', type: 'button', className: 'control',
        value: 'reset', parent: 'control_panel'},
    canvas_panel: {elem: 'div', className: 'canvas-panel'},
    canvas: {elem: 'canvas', id: 'image-view', className: 'dicom-canvas', tabindex:'0', parent: 'canvas_panel'},
    progress: {elem: 'span', className: 'canvas-progress', parent:'canvas_panel'},
    control: {elem: 'select', className: 'control-overlay', children: [
        {elem: 'option', value: 'pan', children: [{elem: 'text', text: 'pan [p]'}]},
        {elem: 'option', value: 'zoom', children: [{elem: 'text', text: 'zoom [z]'}]},
        {elem: 'option', value: 'rotate', children: [{elem: 'text', text: 'rotate [r]'}]},
        {elem: 'option', value: 'scroll', children: [{elem: 'text', text: 'scroll [s]'}]},
        {elem: 'option', value: 'window', children: [{elem: 'text', text: 'window [w]'}]},
        {elem: 'option', value: 'abdomen', children: [{elem: 'text', text: 'window = abdomen [a]'}]},
        {elem: 'option', value: 'pulmonary', children: [{elem: 'text', text: 'window = pulmonary [u]'}]},
        {elem: 'option', value: 'brain', children: [{elem: 'text', text: 'window = brain [b]'}]},
        {elem: 'option', value: 'bone', children: [{elem: 'text', text: 'window = bone [o]'}]},
        {elem: 'option', value: 'reset', children: [{elem: 'text', text: 'reset [e]'}]},
        {elem: 'option', value: 'close', children: [{elem: 'text', text: 'close [c]'}]},
        {elem: 'option', disabled: true, value: 'notes', children: [{elem: 'text', text: '-'}]}
    ], parent:'canvas_panel'}
};

function find_option(select: HTMLSelectElement, text: string) : number {
    for (let i = 0; i < select.length; ++i) {
        if ((<HTMLOptionElement>select.options[i]).value === text) {
            return i;
        }
    }
    return undefined;
}

//----------------------------------------------------------------------------
// Dicom Image Set Object

// *TODO* 
//
// Make Dicom object storable, by separating data from functions, and making the canvas external.

//------------------------------------------------------------------------
// Support for standard web image formats

interface StdImgData {
    data : ArrayBuffer[];
    caption : string;
    db_offset : number;
    data_size : number[];
}

export interface Img {
    render(context : CanvasRenderingContext2D) : Promise<void>;
    render_thumbnail(canvas : HTMLCanvasElement) : Promise<void>;
    dispose() : void;
    hide() : void;
    current_view : Mat3;
    rows : number;
    cols : number;
    frames : number;
    dicom : boolean;
    current_index : number;
    data : ArrayBuffer[];
    caption : string;
    data_size : number[];
}

export function make_std(frames : ArrayBuffer[]) : StdImgData {
    return {
        data : frames,
        caption : null,
        db_offset : null,
        data_size : []
    };
}

/*
export function extractArrayBuffer_std(std : any) : ArrayBuffer {
    const buf = std.data;
    std.data = null;
    return buf;
}

export function emplaceArrayBuffer_std(std : any, buf : ArrayBuffer) {
    std.data = buf;
    buf = null;
}

export function extractBlob_std(std : any) : Blob {
    const blob = new Blob(std.data);
    std.data = null;
    return blob;
}

export function emplaceBlob_std(std : any, blob : Blob) : Promise<void> {
    return new Promise<void>((succ, fail) => {
        const fileReader = new FileReader();
        fileReader.onload = function() {
            std.data = this.result;
            blob = null;
            succ();
        }
        fileReader.readAsArrayBuffer(blob);
    });
}
*/

export class StdImg implements Img {

    canvas : HTMLCanvasElement;
    context : CanvasRenderingContext2D;
    cols : number;
    rows : number;
    current_view : Mat3;
    data : ArrayBuffer[];
    caption : string;
    db_offset : number;
    data_size : number[];
    frames : number;
    dicom : boolean;
    current_index : number;

    constructor(img : StdImgData) {
        this.data = img.data;
        this.caption = img.caption;
        this.db_offset = img.db_offset;
        this.data_size = img.data_size;
        this.current_view = (new Mat3).set_identity();
        this.canvas = null;
        this.rows = null;
        this.cols = null;
        this.frames = 1;
        this.dicom = false;
        this.current_index = 0;
    }

    render(context : CanvasRenderingContext2D) : Promise<void>{
        return new Promise<void>((succ, fail) => {
            let img = new Image();
            let blob = new Blob([this.data[this.current_index]]);

            img.onload = () => {
                context.drawImage(img, 0, 0, img.width, img.height, 0, 0, context.canvas.width, context.canvas.height);
                (URL || webkitURL).revokeObjectURL(blob);
                img = null;
                blob = null;
                succ();
            };

            const url = (URL || webkitURL).createObjectURL(blob);
            img.src = url;
        });
    }

    render_thumbnail(canvas : HTMLCanvasElement) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let img = new Image();
            let blob = new Blob([this.data[0]]);

            img.onload = () => {
                this.cols = img.width;
                this.rows = img.height;
                canvas.height = 100;
                canvas.width = 100 * this.cols / this.rows;
                const cxt = canvas.getContext('2d');
                cxt.drawImage(img, 0, 0, canvas.width, canvas.height);
                (URL || webkitURL).revokeObjectURL(blob);
                img = null;
                blob = null;
                this.data.length = 0;
                resolve();
            };

            img.onerror = (e) => {
                console.log('IMAGE ERROR');
                reject(e);
            };

            const url = (URL || webkitURL).createObjectURL(blob);
            img.src = url;
        });
    }

    dispose() {
        this.data.length = 0;
        this.canvas = null;
    }

    hide() {
        this.data.length = 0;
    }
}

//------------------------------------------------------------------------
// Support for DICOM format images.

// BEGIN: PATCH DICOM PARSER

dicomParser.isEncapsulated = function(element, byteStream) {
    switch(byteStream.transferSyntax) {
        case '1.2.840.10008.1.2.4.57':
        case '1.2.840.10008.1.2.4.70':
        case '1.2.840.10008.1.2.4.90':
            const tag = dicomParser.readTag(byteStream);
            byteStream.seek(-4);
            return tag === 'xfffee000';
        default:
            return false;
    }
}

// END: PATCH DICOM PARSER

function get_data(data_set : any,pixel_data_element : any, len : number, frame : number, frames : number) : ArrayBuffer {
    if (pixel_data_element.encapsulatedPixelData) {
       if (!pixel_data_element.basicOffsetTable.length) {
           if (frames != pixel_data_element.fragments.length) {
               const basicOffsetTable = dicomParser.createJPEGBasicOffsetTable(data_set, pixel_data_element);
               return dicomParser.readEncapsulatedImageFrame(data_set, pixel_data_element, frame, basicOffsetTable);
           }
           return dicomParser.readEncapsulatedPixelDataFromFragments(data_set, pixel_data_element, frame);
       } 
       return dicomParser.readEncapsulatedImageFrame(data_set, pixel_data_element, frame);
    }

    const offset = frame * len;
    return data_set.byteArray.buffer.slice(
        pixel_data_element.dataOffset + offset,
        pixel_data_element.dataOffset + offset + len
    );
}

    
function get_pixel_data(
    data_set : any,
    transfer_syntax : string,
    bytes : number,
    len : number,
    frame : number,
    frames : number
) : ArrayBuffer {
    const pixel_data_element = data_set.elements.x7fe00010;
    const buffer = get_data(data_set, pixel_data_element, len, frame, frames);

    //console.log('transferSyntax:', transfer_syntax);
	
    switch(transfer_syntax) {
        case '1.2.840.10008.1.2.4.57':
        case '1.2.840.10008.1.2.4.70': {
            //console.log('JPEG');
            const decoder = new jpeg.lossless.Decoder();
            return decoder.decode(buffer, 0, buffer.byteLength, bytes).buffer;
        }
        case '1.2.840.10008.1.2.4.90': {
            const decoder = new JpxImage();
            decoder.parse(new Uint8Array(buffer));
            const decoded = decoder.tiles[0].items;
            return decoded.buffer;
        }
        default:
            return buffer;
    }
}

function rescale(rescale_intercept : number, rescale_slope : number, bytes : number, data : ArrayBuffer, signed : boolean, mask : number, sign_bit : number) {
    if (
        rescale_intercept !== undefined && rescale_slope !== undefined
        && ((rescale_intercept != 0.0) || (rescale_slope != 1.0))
    ) {
        const iarray = (signed && (bytes === 1)) ? new Int8Array(data) :
            (signed && (bytes === 2)) ? new Int16Array(data) :
            (signed && (bytes === 4)) ? new Int32Array(data) :
            ((!signed) && (bytes === 1)) ? new Uint8Array(data) :
            ((!signed) && (bytes === 2)) ? new Uint16Array(data) :
            ((!signed) && (bytes === 4)) ? new Uint32Array(data) :
            null;
        const oarray = (bytes === 1) ? new Int8Array(data) :
            (bytes === 2) ? new Int16Array(data) :
            (bytes === 4) ? new Int32Array(data) :
            null;
        const tmin = (bytes < 2) ? -0x80 :
            (bytes < 4) ? -0x8000 : -0x80000000;
        const tmax = (bytes < 2) ? 0x7f :
            (bytes < 4) ? 0x7fff : 0x7fffffff;
        if (iarray != null && oarray != null) {
            if (signed) {
                for (let i = 0; i < iarray.length; ++i) {
                    const tmp = (((iarray[i] & mask) ^ sign_bit) - sign_bit) * rescale_slope + rescale_intercept;
                    if (tmp < tmin) {
                        oarray[i] = tmin;
                    } else if (tmp > tmax) {
                        oarray[i] = tmax;
                    } else {
                        oarray[i] = tmp;
                    }
                }
            } else {
                for (let i = 0; i < iarray.length; ++i) {
                    const tmp = (iarray[i] & mask) * rescale_slope + rescale_intercept;
                    if (tmp < tmin) {
                        oarray[i] = tmin;
                    } else if (tmp > tmax) {
                        oarray[i] = tmax;
                    } else {
                        oarray[i] = tmp;
                    }
                }
            }
            return true;
        } else {
            console.log('UNSUPPORTED WORD SIZE IN RESCALE');
            return signed;
        }
    } else {
        const iarray = (signed && (bytes === 1)) ? new Int8Array(data) :
            (signed && (bytes === 2)) ? new Int16Array(data) :
            (signed && (bytes === 4)) ? new Int32Array(data) :
            ((!signed) && (bytes === 1)) ? new Uint8Array(data) :
            ((!signed) && (bytes === 2)) ? new Uint16Array(data) :
            ((!signed) && (bytes === 4)) ? new Uint32Array(data) :
            null;
        if (iarray != null) {
            if (signed) {
                for (let i = 0; i < iarray.length; ++i) {
                    iarray[i] = ((iarray[i] & mask) ^ sign_bit) - sign_bit;
                }
            } else {
                for (let i = 0; i < iarray.length; ++i) {
                    iarray[i] = iarray[i] & mask;
                }
            }
        } else {
            console.log('UNSUPPORTED WORD SIZE IN MASKING');
        }
        return signed
    }
}

interface DicomData {
    dicom : boolean;
    signed : boolean;
    original_signed : boolean;
    windowed : boolean;
    frames : number;
    rows : number;
    cols : number;
    bytes : number;
    window_center : number;
    window_width : number;
    mask : number;
    sign_bit : number;
    unsigned_mask : number;
    samples_per_pixel : number;
    photometric_interpretation : string;
    planar_configuration : number; 
    modality : string;
    alpha : number,
    data : ArrayBuffer[];
    sizes : [number, number][];
    canvas: HTMLCanvasElement;
    context : CanvasRenderingContext2D;
    current_index : number;
    current_brightness : number;
    current_contrast : number;
    current_view : number;
    caption : string;
    indexes : number[];
    db_offset : number;
    data_size : number[];
}

export function heap_size(dicom : DicomData) : number {
    return renderModule.nextValidHeapSize((4 + dicom.bytes * dicom.samples_per_pixel) * dicom.rows * dicom.cols);
}

function greyscale(cols:number, rows:number, bytes:number, samples_per_pixel:number, data:ArrayBuffer):boolean {
    let src = 0
    , in_data = new Uint8Array(data)
    , count = (cols * rows)
    ;

    for (let i = 0; i < count; ++i) {
        let p = in_data[src + bytes - 1];
        for (let d = bytes - 2; d >= 0; --d) {
            p <<= 8;
            p += in_data[src + d];
        }
        src += bytes;

        for (let c = samples_per_pixel; c > 1; --c) {
            let q =  in_data[src + bytes - 1];
            for (let d = bytes - 2; d >= 0; --d) {
                p <<= 8;
                p += in_data[src + d];
            }
            src += bytes;
            if (p !== q) return false;
        }
    }
    return true;
}

export function make_dicom(byte_array : ArrayBuffer, set_length : number) : DicomData {
    let data_set = dicomParser.parseDicom(new Uint8Array(byte_array))
    , pixel_data_element = data_set.elements.x7fe00010
    , bytes = data_set.uint16('x00280100') >> 3
    , windowed = true
    , window_center = data_set.string('x00281050') 
    , window_width = data_set.string('x00281051')
    , min_pixel = data_set.uint16('x00280106') | 0
    , max_pixel = data_set.uint16('x00280107') | 0
    , high_bit = Math.pow(2, data_set.uint16('x00280101')) | 0
    , frames = data_set.intString('x00280008') // parseInt(data_set.string('x00280008')) | 0
    , samples_per_pixel =  data_set.uint16('x00280002') | 0
    , photometric_interpretation = data_set.string('x00280004') || "MONOCHROME2"
    , modality = data_set.string('x00080060')
    , planar_configuration = data_set.uint16('x00280006') | 0
    , rescale_intercept = data_set.floatString('x00281052')
    , rescale_slope = data_set.floatString('x00281053')
    , sign_bit = high_bit >> 1
    , mask = high_bit - 1
    , original_signed = (data_set.uint16('x00280103') === 1)
    , rows = data_set.uint16('x00280010') | 0
    , cols = data_set.uint16('x00280011') | 0
    , transfer_syntax = data_set.string('x00020010')
    , sizes : [number, number][] = []
    , data_size : number[] = []
    ;

    if (!frames) {
        frames = 1;
    }

    const len = rows * cols * bytes * samples_per_pixel;
    //const data = new ArrayBuffer(len * set_length);
    const data : ArrayBuffer[] = [];

    //console.log('ORIGINAL SIGNED: ', signed);

    let signed = original_signed;
    for (let i = 0; i < frames; ++i) {
        const px = get_pixel_data(data_set, transfer_syntax, bytes, len, i, frames);
        //if (photometric_interpretation === 'RGB') {
        //    console.log('RGB -> GREYSCALE: ', greyscale(cols, rows, bytes, samples_per_pixel, px))
        //}
        signed = rescale(rescale_intercept, rescale_slope, bytes, px, original_signed, mask, sign_bit) || signed
        //signed = true;
        //data.push(pako.deflateRaw(px, {level: 1}).buffer);
        data.push(px);
        data_size.push(px.byteLength);
        sizes.push([cols, rows]);
        //new Uint8Array(data).set(new Uint8Array(px));
    }

    //console.log('PHOTOMETRIC INTERPRETATION: ', photometric_interpretation);
    //console.log('ORIGINAL SIGNED: ', original_signed);
    //console.log('SIGNED: ', signed);
    //console.log('BYTES: ', bytes);
    //console.log('MASK: ', mask.toString(16));
    //console.log('SIGNBIT: ', sign_bit.toString(16));

    //if (photometric_interpretation !== 'MONOCHROME2'
    //    && photometric_interpretation !== 'MONOCHROME1'
    //) {
    //    windowed = false
    //    window_width = (high_bit - 1)
    //    window_center = (high_bit - 1) / 2
    //} else if (window_width && window_center) {

    if (window_width && window_center) {
        window_center = parseInt(window_center);
        window_width = parseInt(window_width);
    } 
    if (!(window_width && window_center)) { // Fix for 0±0 images.
        window_center = (max_pixel - min_pixel) / 2;
        window_width = max_pixel - min_pixel;
    }

    //console.log('make dicom: ', cols, 'x ', rows);

    return {
        dicom: true,
        signed: signed,
        original_signed: original_signed,
        frames: frames,
        rows: rows,
        cols: cols,
        bytes: bytes,
        windowed : windowed,
        window_center: window_center,
        window_width: window_width,
        mask: mask | 0,
        sign_bit: sign_bit | 0,
        unsigned_mask: sign_bit - 1 | 0,
        samples_per_pixel: samples_per_pixel,
        photometric_interpretation: photometric_interpretation,
        modality: modality,
        planar_configuration: planar_configuration,
        alpha: 255 | 0,
        data: data,
        sizes: sizes,
        canvas: null,
        context: null,
        current_index: 0,
        current_brightness: 0,
        current_contrast: 0,
        current_view: null,
        caption: '',
        indexes: [],
        db_offset: null,
        data_size: []
    }
}

/*
export function extractArrayBuffer_dicom(dicom : any) : ArrayBuffer {
    dicom.indexes = [];
    let end = 0;
    for (let i = 0; i < dicom.data.length; ++i) {
        end += dicom.data[i].byteLength;
        dicom.indexes[i] = end;
    }
    let ui8 = new Uint8Array(end);
    end = 0;
    for (let i = 0; i < dicom.data.length; ++i) {
        ui8.set(dicom.data[i], end);
        end += dicom.data[i].byteLength;
    }
    dicom.data = [];
    return ui8.buffer;
}

export function emplaceArrayBuffer_dicom(dicom : any, buf : ArrayBuffer) {
    var start = 0;
    for (let i = 0; i < dicom.indexes.length; ++i) {
        dicom.data.push(buf.slice(start, dicom.indexes[i]));
        start += dicom.indexes[i];
    }
    dicom.indexes = [];
}

export function extractBlob_dicom(dicom : any) : Blob {
    dicom.indexes = [];
    let end = 0;
    let blob = new Blob([]);
    for (let i = 0; i < dicom.data.length; ++i) {
        end = end + dicom.data[i].byteLength;
        dicom.indexes[i] = end;
        blob = new Blob([blob, dicom.data[i]]);
        dicom.data[i] = null;
    }
    dicom.data = [];
    return blob;
}

export function emplaceBlob_dicom(dicom : any, blob : Blob) : Promise<void> {
    return dicom.indexes.reduce((promise : Promise<number>, end : number, j : number) => {
        return promise.then((start) => {
            return new Promise((succ, fail) => {
                const fileReader = new FileReader();
                fileReader.onload = function() {
                    dicom.data[j] = this.result;
                    succ(end);
                }
                fileReader.readAsArrayBuffer(blob.slice(start, end));
            });
        });
    }, Promise.resolve(0)).then(() => {
        dicom.indexes = [];
    });
}
*/

export function compatible(d1 : DicomData, d2 : DicomData) : boolean {
    //return (d1.rows === d2.rows) &&
    //(d1.cols === d2.cols) &&
    return (d1.bytes === d2.bytes) &&
    (d1.mask === d2.mask) &&
    (d1.samples_per_pixel === d2.samples_per_pixel) &&
    (d1.photometric_interpretation === d2.photometric_interpretation) &&
    (d1.planar_configuration === d2.planar_configuration)
}

export function add_dicom(dicom : any, byte_array : ArrayBuffer) : any {
    let data_set = dicomParser.parseDicom(new Uint8Array(byte_array))
    , pixel_data_element = data_set.elements.x7fe00010
    , samples_per_pixel =  data_set.uint16('x00280002') | 0
    , photometric_interpretation = data_set.string('x00280004') || "MONOCHROME2"
    , planar_configuration = data_set.uint16('x00280006') | 0
    , frames = data_set.intString('x00280008') 
    , rows = data_set.uint16('x00280010') | 0
    , cols = data_set.uint16('x00280011') | 0
    , bytes = data_set.uint16('x00280100') >> 3
    , high_bit = Math.pow(2, data_set.uint16('x00280101')) | 0
    , mask = high_bit - 1
    , sign_bit = high_bit >> 1
    //, signed = (data_set.intString('x00280103') === 1)
    , min_pixel = data_set.uint16('x00280106') | 0
    , max_pixel = data_set.uint16('x00280107') | 0
    , rescale_intercept = data_set.floatString('x00281052')
    , rescale_slope = data_set.floatString('x00281053')
    , transfer_syntax = data_set.string('x00020010')
    ;

    //if (dicom.rows !== rows) {
    //    console.log("rows mismatch " + dicom.rows + " <> " + rows);
    //    return dicom;
    //}
    //if (dicom.cols !== cols) {
    //    console.log("cols mismatch" + dicom.cols + " <> " + cols);
    //    return dicom;
    //}

    let valid = true;
    if (dicom.bytes !== bytes) {
        console.log("bytes mismatch", dicom.bytes, "<>", bytes);
        valid = false;
    }
    if (dicom.mask !== mask) {
        console.log("bitmask mismatch", dicom.mask, "<>", mask);
        valid = false;
    }
    if (dicom.samples_per_pixel !== samples_per_pixel) {
        console.log("samples per pixel mismatch", dicom.samples_per_pixel, "<>", samples_per_pixel);
        valid = false;
    }
    if (dicom.photometric_interpretation !== photometric_interpretation) {
        console.log("photometric interpretation mismatch",
        dicom.photometric_interpretation, "<>", photometric_interpretation);
        valid = false;
    }
    if (dicom.planar_configuration !== planar_configuration) {
        console.log("planar configuration mismatch", dicom.planar_configuration, "<>", planar_configuration);
        valid = false;
    }
    if (!valid) {
        return dicom;
    }

    if (!frames) {
        frames = 1;
    }

    const len = rows * cols * bytes * samples_per_pixel;

    for (let i = 0; i < frames; ++i) {
        const px = get_pixel_data(data_set, transfer_syntax, bytes, len, i, frames);
        rescale(rescale_intercept, rescale_slope, bytes, px, dicom.original_signed, dicom.mask, dicom.sign_bit);
        //dicom.data.push(pako.deflateRaw(px, {level: 1}).buffer);
        dicom.data.push(px);
        dicom.data_size.push(px.byteLength);
        dicom.sizes.push([cols, rows]);
        //new Uint8Array(dicom.data).set(new Uint8Array(px), len * frames);
    }

    //console.log('PHOTOMETRIC INTERPRETATION: ', photometric_interpretation);
    //console.log('SIGNED: ', dicom.signed);
    //console.log('BYTES: ', bytes);
    //console.log('MASK: ', mask.toString(16));
    //console.log('SIGNBIT: ', sign_bit.toString(16));
    
    //console.log('add dicom: ', cols, 'x ', rows);

    dicom.frames += frames;
    return dicom; 
} 

export class Dicom implements Img {

    public readonly dicom : boolean;
    signed : boolean;
    windowed : boolean;
    frames: number;
    rows : number;
    cols : number;
    bytes : number;
    window_center : number;
    window_width : number;
    mask : number;
    sign_bit : number;
    unsigned_mask : number;
    samples_per_pixel : number;
    photometric_interpretation : string;
    modality : string;
    planar_configuration : number;
    alpha : number;
    current_index : number;
    current_brightness : number;
    current_contrast : number;
    caption : string;
    saved_index : number;
    current_view : Mat3;
    db_offset : number;
    data_size : number[];
    
    data : ArrayBuffer[];
    sizes : [number, number][];
    canvas : HTMLCanvasElement;
    context : CanvasRenderingContext2D;
    heap : ArrayBuffer;
    asm : (pmin : number, pmax : number, pscl : number, n : number) => void;

    constructor(img : DicomData) {
        this.dicom = img.dicom;
        this.signed = img.signed;
        this.windowed = img.windowed;
        this.frames = img.frames;
        this.rows = img.rows;
        this.cols = img.cols;
        this.bytes = img.bytes;
        this.window_center = img.window_center;
        this.window_width = img.window_width;
        this.mask = img.mask;
        this.sign_bit = img.sign_bit;
        this.unsigned_mask = img.unsigned_mask;
        this.samples_per_pixel = img.samples_per_pixel;
        this.photometric_interpretation = img.photometric_interpretation;
        this.modality = img.modality;
        this.planar_configuration = img.planar_configuration;
        this.alpha = img.alpha;
        this.current_index = img.current_index;
        this.current_brightness = img.current_brightness;
        this.current_contrast = img.current_contrast;
        this.caption = img.caption || '...';
        this.current_view = null;
        this.db_offset = img.db_offset;
        this.data_size = img.data_size;

        this.data = img.data;
        this.sizes = img.sizes;
        this.canvas = img.canvas;
        this.context = img.context;
        const n = Math.max.apply(this, this.sizes.map(([x, y]) => x * y));
        this.heap = new ArrayBuffer(renderModule.nextValidHeapSize((4 + this.bytes * this.samples_per_pixel) * n));
        const rmod = renderModule.asm({
            Math: Math,
            Uint8Array: Uint8Array,
            Uint16Array: Uint16Array,
            Uint32Array: Uint32Array,
            Int8Array: Int8Array,
            Int16Array: Int16Array,
            Int32Array: Int32Array,
        }, this, this.heap);

        if (this.photometric_interpretation === 'MONOCHROME1' &&
            this.samples_per_pixel === 1 &&
            this.bytes === 1 &&
            !this.signed)
        {
            this.asm = rmod.render8inverted;
        } else if (this.photometric_interpretation === 'MONOCHROME1' &&
            this.samples_per_pixel === 1 &&
            this.bytes === 2 &&
            !this.signed) 
        {
            this.asm = rmod.render16inverted_unsigned;
        } else if (this.photometric_interpretation === 'MONOCHROME1' &&
            this.samples_per_pixel === 1 &&
            this.bytes === 2 &&
            this.signed)
        {
            this.asm = rmod.render16inverted_signed;
        } else if (this.photometric_interpretation === 'MONOCHROME2' &&
            this.samples_per_pixel === 1 &&
            this.bytes === 1 &&
            !this.signed)
        {
            this.asm = rmod.render8;
        } else if (this.photometric_interpretation === 'MONOCHROME2' &&
            this.samples_per_pixel === 1 &&
            this.bytes === 2 &&
            !this.signed)
        {
            this.asm = rmod.render16unsigned;
        } else if (this.photometric_interpretation === 'MONOCHROME2' &&
            this.samples_per_pixel === 1 &&
            this.bytes === 2 &&
            this.signed)
        {
            this.asm = rmod.render16signed;
        } else if (this.photometric_interpretation === 'RGB' &&
            this.samples_per_pixel === 3 &&
            this.bytes === 1 &&
            !this.signed)
        {
            this.asm = rmod.render8rgb;
        } else {
            this.asm = rmod.unsupported;
        }

        this.current_index = 0;
        //if (this.window_width <= 0 && this.data.length > 0) {
        //    this.min_max();
        //}
        this.current_brightness = this.window_center;
        this.current_contrast = this.window_width;
        this.current_view = (new Mat3).set_identity();
    }

    render(context : CanvasRenderingContext2D) : Promise<void> {
        const pmin = this.current_brightness - 0.5 - (this.current_contrast - 1.0) / 2.0
        , pmax = this.current_brightness - 0.5 + (this.current_contrast - 1.0) / 2.0
        , pscl = 255.0 / (pmax - pmin)
        , [w, h] = this.sizes[this.current_index]
        , count = w * h 
        ;
        if (this.data[this.current_index]) {
            var t1 = performance.now();
            new Uint8Array(this.heap, 4 * count).set(new Uint8Array(this.data[this.current_index]));
            this.asm(pmin, pmax, pscl, count);
            const clamped = new Uint8ClampedArray(this.heap, 0, 4 * count);
            const img_data = new ImageData(clamped, w, h);
            context.putImageData(img_data, 0, 0);
            var t2 = performance.now();
            console.log('RENDER TIME:', t2 - t1, 'ms');
            //context.putImageData(new ImageData(new Uint8ClampedArray(this.heap, 0, 4 * count), w, h), 0, 0);
        } else {
            context.clearRect(0, 0, w, h);
            context.font = '1.6rem "Noto Sans"';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillStyle = '#0f72c3';
            context.fillText('Loading image ' + (this.current_index + 1) + ' ...', w / 2.0, h / 2.0);
        }
        return Promise.resolve();
    }

    min_max() {
        let src = 0
        //, in_data = new Uint8Array(pako.inflateRaw(this.data[this.current_index]).buffer)
        , in_data = new Uint8Array(this.data[this.current_index])
        //, len = this.rows * this.cols * this.bytes * this.samples_per_pixel
        //, in_data = new Uint8Array(this.data, this.current_index * len, len)
        , count = (this.cols * this.rows)
        , min, max
        ;

        if (this.signed) {
            max = this.mask;
            min = this.unsigned_mask;
        } else {
            max = 0;
            min = this.mask;
        }

        for (let i = 0; i < count; ++i) {
            let p = in_data[src + this.bytes - 1];
            for (let d = this.bytes - 2; d >= 0; --d) {
                p <<= 8;
                p += in_data[src + d];
            }
            src += this.bytes;

            if (this.signed) {
                if (p & this.sign_bit) {
                    p &= this.unsigned_mask;
                } else {
                    p = this.sign_bit + (p & this.unsigned_mask);
                }
            } else { 
                p &= this.mask;
            }

            if (p > max) {
                max = p;
            }
            if (p < min) {
                min = p;
            }
        }
        
        this.window_center = (min + max) / 2.0;
        this.window_width = max - min;
    }

    render_thumbnail(canvas : HTMLCanvasElement) : Promise<void> {
        const cxt = canvas.getContext('2d');
        canvas.height = 100;
        canvas.width = Math.floor(100 * this.cols / this.rows);
        const c = document.createElement('canvas');
        c.width = this.cols;
        c.height = this.rows;
        const context = c.getContext('2d');
        if (this.window_width <= 0) {
            this.min_max();
            this.current_brightness = this.window_center;
            this.current_contrast = this.window_width;
        }

        return this.render(context).then(() => {
            this.data.length = 0; // does this release a larger fragment?
            cxt.drawImage(c, 0, 0, c.width, c.height, 0, 0, canvas.width, canvas.height);
        });
    }

    hide() {
        this.data.length = 0;
    }

    dispose() {
        this.data = null;
        this.canvas = null;
        this.context = null;
        this.heap = null;
        this.asm = null;
    }
}

//----------------------------------------------------------------------------
// DICOM Viewer

interface Point {
    clientX : number;
    clientY : number;
}

class Control {
    onstart : (c : Control, x : number, y : number) => void;
    onstep : (x : number, y : number) => void;
    onend : () => void;
    readonly css_class : string;

    start(point : Point, canvas : HTMLElement) {
        const rect = canvas.getBoundingClientRect();
        this.onstart(this,
            point.clientX - rect.left,
            point.clientY - rect.top
        );
    }

    step(point : Point, canvas : HTMLElement) {
        const rect = canvas.getBoundingClientRect();
        this.onstep(
            point.clientX - rect.left,
            point.clientY - rect.top
        );
    }
    
    end() {
        this.onend();
    }

    clear() {
        this.onstart = null;
        this.onstep = null;
        this.onend = null;
    }   

    constructor(css_class : string) {
        this.clear();
        this.css_class = css_class;
    }
}

interface DicomViewerArgs {
    parent: HTMLElement,
    fullscreen_parent: HTMLElement,
    after: HTMLElement,
    size_reference: HTMLElement,
    get_frames: (dicom: Img) => Promise<void>,
    get_image_begin: (image: Img) => Promise<void>,
    get_image_frame: (image: Img, frame: number) => Promise<ArrayBuffer>,
    get_image_end: () => Promise<void>,
    get_navigating?: () => boolean,
}

export class DicomViewer {
    args : DicomViewerArgs;
    ui : {[index:string] : Node};
    current_dicom : Img;
    canvas : HTMLCanvasElement;
    context : CanvasRenderingContext2D;
    fit_transform : Mat3;
    left : Control;
    right : Control;
    rem : number;
    onclose : () => void;
    parent : HTMLElement;
    size_reference : HTMLElement;
    render_requested : boolean;
    post_frame : () => void;
    get_frames : (dicom: Dicom) => Promise<void>;
    get_image_begin: (image: Img) => Promise<void>;
    get_image_frame: (image: Img, frame: number) => Promise<ArrayBuffer>;
    get_image_end: () => Promise<void>;
    selected_control : number;

    private fullscreen : boolean = false;

    constructor(args :DicomViewerArgs) {
        this.args = args; /* FIXME remove duplicates */
        this.parent = args.parent;
        this.size_reference = args.size_reference;
        this.get_frames = args.get_frames;
        this.get_image_begin = args.get_image_begin;
        this.get_image_frame = args.get_image_frame;
        this.get_image_end = args.get_image_end;
        this.render_requested = false;
        this.post_frame = null;
        this.canvas = null;
    }

    private readonly frame_fn = () => {
        this.frame();
        this.render_requested = false;
    };

    t1 : number = performance.now();
    t2 : number = performance.now();
    s1 : number = 0;
    s2 : number = 0;
    ct : number = 0;

    private readonly render_fn = () => {
        if (this.current_dicom) {
            //this.t1 = performance.now();
            //console.log('DELTA:', this.t1 - this.t2);
            this.current_dicom.render(this.canvas.getContext('2d')).then(() => {
                //this.t2 = performance.now();
                //this.s1 += this.t2 - this.t1;
                //++this.ct;
                //console.log('RENDER:', this.s1 / this.ct);
                //this.t1 = this.t2;
                this.frame();
                // this.t2 = performance.now();
                //this.s2 += this.t2 - this.t1;
                //console.log('FRAME:', this.s2 / this.ct);
                this.render_requested = false;
            });
        }
    };

    //------------------------------------------------------------------------
    // Drawing Frames

    frame() {
        this.context.setTransform(1, 0, 0, 1, 0, 0);
        this.context.clearRect(0, 0,
            (this.ui.canvas as HTMLCanvasElement).width,
            (this.ui.canvas as HTMLCanvasElement).height
        );
        if (this.current_dicom && this.canvas) {
            const u = this.fit_transform;
            this.context.setTransform(
                u.mat3[0], u.mat3[1], u.mat3[3],
                u.mat3[4], u.mat3[6], u.mat3[7]
            );
            const v = this.current_dicom.current_view;
            this.context.transform(
                v.mat3[0], v.mat3[1], v.mat3[3],
                v.mat3[4], v.mat3[6], v.mat3[7]
            );
            this.context.drawImage(this.canvas, 0, 0);
        }
        if (this.post_frame) {
            this.post_frame();
        }
    }

    request_render() { 
        if (!this.render_requested) {
            this.render_requested = true;
            window.requestAnimationFrame(this.render_fn);
        }
    }

    request_frame() {
        if (!this.render_requested) {
            this.render_requested = true;
            window.requestAnimationFrame(this.frame_fn);
        }
    }

    //------------------------------------------------------------------------
    // Image Controls

    custom_window() {
        (this.ui.window_control as HTMLSelectElement).options.item(0).text = Math.round((this.current_dicom as Dicom).current_brightness)
            + ' \u00b1 ' + (Math.round((this.current_dicom as Dicom).current_contrast) / 2);
        (this.current_dicom as Dicom).saved_index = 0;
        (this.ui.window_control as HTMLSelectElement).selectedIndex = 0;
    }

    public readonly canvas_click_handler = (event : MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    public readonly canvas_contextmenu_handler = (event : MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    private toggle_fullscreen() {
        this.fullscreen = !this.fullscreen;
        remove_node(this.ui.canvas_panel);
        if (this.fullscreen && this.args.fullscreen_parent) {
            this.args.fullscreen_parent.appendChild(this.ui.canvas_panel);
            //getReqFullscreen().call(this.ui.canvas_panel);
            (this.ui.canvas_panel as HTMLElement).className = 'canvas-panel-fullscreen';
            //(this.ui.canvas as HTMLElement).className = 'dicom-canvas-fullscreen';
        } else {
            this.args.parent.appendChild(this.ui.canvas_panel);
            //getExitFullscreen().call(document);
            (this.ui.canvas_panel as HTMLElement).className = 'canvas-panel';
            //(this.ui.canvas as HTMLElement).className = 'dicom-canvas';
        }
        setImmediate(() => {
            this.resize();
            setImmediate(() => {
                this.resize(true);
                this.scrollToImage();
                (this.ui.canvas as HTMLElement).focus();
            });
        });
    }

    public readonly canvas_dblclick_handler = (event : MouseEvent) => {
        this.toggle_fullscreen();
        event.preventDefault();
    }

    public readonly canvas_mousedown_handler = (event : MouseEvent) => {
        (this.ui.canvas as HTMLCanvasElement).focus();
        const button = (event.button === 2) ? this.right : this.left;
        if (button.onstart) {
            button.start(event, this.ui.canvas as HTMLElement);
            event.preventDefault();
        }
    }

    public readonly canvas_touchstart_handler = (event : TouchEvent) => {
        const t = event.currentTarget as HTMLCanvasElement
        , t2 = event.timeStamp
        , t1 = parseFloat(t.dataset.lastTouch) || t2
        , dt = t2 - t1
        , fingers = event.touches.length
        ;
        t.dataset.lastTouch = t2.toString();

        if (!dt || dt > 500 || fingers > 1) {
            if (this.left.onstart) {
                this.left.start(event.changedTouches[0], this.ui.canvas as HTMLElement);
                event.preventDefault();
            }
        } else {
            this.toggle_fullscreen();
            event.preventDefault();
        }
    }

    public readonly window_mousemove_handler = (event : MouseEvent) => {
        if (this.left.onstep) {
            this.left.step(event, this.ui.canvas as HTMLElement);
            event.preventDefault();
        } else if (this.right.onstep) {
            this.right.step(event, this.ui.canvas as HTMLElement);
            event.preventDefault();
        }
    }

    public readonly window_touchmove_handler = (event : TouchEvent) => {
        if (this.left.onstep) {
            this.left.step(event.changedTouches[0], this.ui.canvas as HTMLElement);
            event.preventDefault();
        }
    }

    public readonly window_mouseup_handler = (event : MouseEvent) => {
        if (this.left.onend) {
            this.left.end();
            event.preventDefault();
        } else if (this.right.onend) {
            this.right.end();
            event.preventDefault();
        }
    }

    public readonly window_touchend_handler = (event : TouchEvent) => {
        if (this.left.onend) {
            this.left.end();
            event.preventDefault();
        }
    }

    clear_control(element: HTMLElement, matching : string) {
        if (element.className === matching) {
            element.className = 'control';
        }
    } 

    clear_controls(button : Control) {
        this.clear_control(this.ui.pan_control as HTMLElement, button.css_class);
        this.clear_control(this.ui.zoom_control as HTMLElement, button.css_class);
        this.clear_control(this.ui.rotate_control as HTMLElement, button.css_class);
        this.clear_control(this.ui.params_control as HTMLElement, button.css_class);
        button.clear();
    }

    // Pan Contol

    private readonly pan_start = (button : Control, start_x : number, start_y : number) => {
        const start_m = (new Mat3).copy(this.current_dicom.current_view);
        const pan_step = (end_x : number, end_y : number) => {
            const start_v = (new Vec3).set(end_x - start_x, end_y - start_y, 0).pre_mul_mat3(
                    (new Mat3).set_multiply(this.current_dicom.current_view, this.fit_transform).invert()
            );
            this.current_dicom.current_view.copy(start_m);
            this.current_dicom.current_view.pre_translate(start_v.vec3[0], start_v.vec3[1]);
            this.request_frame();
        }
        const pan_end = () => {
            button.onstep = null;
            button.onend = null;
        }
        button.onstep = pan_step;
        button.onend = pan_end;
    }

    private start_v = new Vec3;

    private readonly draw_circle = () => {
        this.context.beginPath();
        this.context.strokeStyle='#ffff00';
        this.context.arc(this.start_v.vec3[0], this.start_v.vec3[1], 3, 0, 2*Math.PI);
        this.context.stroke();
    };

    // Zoom Control
    
    private readonly zoom_start = (button : Control, start_x : number, start_y : number) => {
        const start_m = (new Mat3).copy(this.current_dicom.current_view);
        this.start_v.set(start_x, start_y, 1).pre_mul_mat3(
            (new Mat3).set_multiply(this.current_dicom.current_view, this.fit_transform).invert()
        );
        this.post_frame = this.draw_circle;
        this.request_frame();
        const zoom_step = (end_x : number, end_y : number) => {
            const scale = Math.pow(1.01, start_y - end_y);
            this.current_dicom.current_view.copy(start_m);
            this.current_dicom.current_view.pre_scale_at(scale, scale, this.start_v.vec3[0], this.start_v.vec3[1]);
            this.request_frame();
        }
        const zoom_end = () => {
            button.onstep = null;
            button.onend = null;
            this.post_frame = null;
            this.request_frame();
        }
        button.onstep = zoom_step;
        button.onend = zoom_end;
    }

    // Rotate Control

    private readonly rotate_start = (button : Control, start_x : number, start_y : number) => {
        const start_m = (new Mat3).copy(this.current_dicom.current_view);
        this.start_v.set(start_x, start_y, 1).pre_mul_mat3(
            (new Mat3).set_multiply(this.current_dicom.current_view, this.fit_transform).invert()
        );
        this.post_frame = this.draw_circle;
        this.request_frame();
        const rotate_step = (end_x : number, end_y : number) => {
            const angle = (end_y - start_y) * 2 * Math.PI / (this.ui.canvas as HTMLCanvasElement).height;
            this.current_dicom.current_view.copy(start_m);
            this.current_dicom.current_view.pre_rotate_at(angle, this.start_v.vec3[0], this.start_v.vec3[1]);
            this.request_frame();
        }
        const rotate_end = () => {
            button.onstep = null;
            button.onend = null;
            this.post_frame = null;
            this.request_frame();
        }
        button.onstep = rotate_step;
        button.onend = rotate_end;
    }

    // Params Control
    
    private readonly params_start = (button : Control, start_x : number, start_y : number) => {
        const width = 2.0 * this.calc_width();
        const height = 2.0 * this.calc_height();
        const start_b = (this.current_dicom as Dicom).current_brightness;
        const start_c = (this.current_dicom as Dicom).current_contrast;
        let last_x = start_x;
        let last_y = start_y; 
        const params_step = (end_x : number, end_y : number) => {
            if (end_x !== last_x || end_y !== last_y) {
                last_x = end_x;
                last_y = end_y;
                const dx = ((end_x - start_x) * (this.current_dicom as Dicom).window_width) / (2 * width);
                const dy = ((end_y - start_y) * (this.current_dicom as Dicom).window_center) / height;
                (this.current_dicom as Dicom).current_brightness = start_b + Math.sign(dy) * Math.pow(Math.abs(dy), 1.2);
                (this.current_dicom as Dicom).current_contrast = start_c + Math.sign(dx) * Math.pow(Math.abs(dx), 1.2);
                if ((this.current_dicom as Dicom).current_contrast < 1.0) {
                    (this.current_dicom as Dicom).current_contrast = 1.0;
                }
                //this.current_dicom.current_contrast = (start_c * Math.pow(1.01, end_x - start_x));
                this.update_window();
                this.request_render();
            }
        }
        const params_end = () => {
            button.onstep = null;
            button.onend = null;
        }
        button.onstep = params_step;
        button.onend = params_end;
    }

    // Scrolling
    
    private readonly scroll_start = (button : Control, start_x : number, start_y : number) => {
        const start_i = this.current_dicom.current_index;
        const scroll_step = (end_x : number, end_y : number) => {
            const w = this.current_dicom.current_index;
            const z = this.current_dicom.frames;
            if (z > 0) {
                let j = (start_i + (end_y - start_y) * 2 * z / (<HTMLCanvasElement>this.ui.canvas).height) | 0;
                while (j < 0) {
                    j += z;
                }
                while (j >= z) {
                    j -= z;
                }
                if (j !== w) {
                    this.current_dicom.current_index = j;
                    this.update_scroll();
                    this.post_frame = null;
                    this.request_render();
                }
            }
        }
        const scroll_end = () => {
            button.onstep = null;
            button.onend = null;
        }
        button.onstep = scroll_step;
        button.onend = scroll_end;
    };

    // Control Mode
    
    private update_scroll() {
        const sel = <HTMLSelectElement>this.ui.control;
        sel.options[find_option(sel, 'scroll')].
            firstChild.textContent = 'scroll (' +
                (this.current_dicom.current_index + 1) + '/' + 
                this.current_dicom.frames + ')';
    }
    
    private update_window() {
        const sel = <HTMLSelectElement>this.ui.control;
        if (this.current_dicom.dicom) {
            sel.options[find_option(sel, 'window')].
                firstChild.textContent = 'window (' +
                    Math.round((this.current_dicom as Dicom).current_brightness) + ' \u00b1 ' +
                    (Math.round((this.current_dicom as Dicom).current_contrast) / 2) + ')';
        } else {
            (sel.options[find_option(sel, 'window')] as HTMLOptionElement).hidden = true;
            (sel.options[find_option(sel, 'window')] as HTMLOptionElement).disabled = true;
        }
    }
    
    private update_notes() {
        const sel = this.ui.control as HTMLSelectElement;
        const current_dicom = this.current_dicom as Dicom;
        if (current_dicom.dicom) {
            sel.options[find_option(sel, 'notes')].
                firstChild.textContent =
                    '[modality = ' + current_dicom.modality + ']' + 
                    '[size = ' + (Math.ceil(current_dicom.data_size[current_dicom.current_index] / 104851) / 10) + ']';
        } else {
            (sel.options[find_option(sel, 'notes')] as HTMLOptionElement).hidden = true;
            (sel.options[find_option(sel, 'notes')] as HTMLOptionElement).disabled = true;
        }
    }

    public readonly canvas_keydown_handler = (event : KeyboardEvent) => {
        const n = (event.target as HTMLElement).tagName;
        if (n === 'INPUT' || n === 'TEXTAREA') {
            // catch all keyboard input unless it comes from
            // and INPUT or TEXTAREA.
            return;
        }
        const t = this.ui.control as HTMLSelectElement;
        switch (event.code) {
            case 'KeyP':
                t.selectedIndex = find_option(t, 'pan');
                this.control_change_handler();
                break;
            case 'KeyZ':
                t.selectedIndex = find_option(t, 'zoom');
                this.control_change_handler();
                break;
            case 'KeyR':
                t.selectedIndex = find_option(t, 'rotate');
                this.control_change_handler();
                break;
            case 'KeyS':
                t.selectedIndex = find_option(t, 'scroll');
                this.control_change_handler();
                break;
            case 'KeyW': 
                t.selectedIndex = find_option(t, 'window');
                this.control_change_handler();
                break;
            case 'KeyA':
                t.selectedIndex = find_option(t, 'abdomen');
                this.control_change_handler();
                break;
            case 'KeyU':
                t.selectedIndex = find_option(t, 'pulmonary');
                this.control_change_handler();
                break;
            case 'KeyB':
                t.selectedIndex = find_option(t, 'brain');
                this.control_change_handler();
                break;
            case 'KeyO':
                t.selectedIndex = find_option(t, 'bone');
                this.control_change_handler();
                break;
            case 'KeyE':
                t.selectedIndex = find_option(t, 'reset');
                this.control_change_handler();
                break;
            case 'KeyC':
            case 'Escape':
                t.selectedIndex = find_option(t, 'close');
                this.control_change_handler();
                break
            case 'Enter':
                t.focus();
                break;
            case 'ArrowUp': {
                switch ((<HTMLOptionElement>t.options[t.selectedIndex]).value) {
                    case 'pan': 
                        this.current_dicom.current_view.pre_translate(0, -1);
                        this.request_frame();
                        break;
                    case 'zoom':
                        this.current_dicom.current_view.pre_scale_at(1.01, 1.01,
                            this.current_dicom.cols / 2, this.current_dicom.rows / 2);
                        this.request_frame();
                        break;
                    case 'rotate':
                        this.current_dicom.current_view.pre_rotate_at(0.01,
                            this.current_dicom.cols / 2, this.current_dicom.rows / 2);
                        this.request_frame();
                        break;
                    case 'scroll':
                        if (this.current_dicom.frames > 0) {
                            let j = this.current_dicom.current_index - 1;
                            while (j < 0) {
                                j += this.current_dicom.frames;
                            }
                            if (j != this.current_dicom.current_index && this.current_dicom.data[j]) {
                                this.current_dicom.current_index = j;
                                this.update_scroll();
                                this.request_render();
                            }
                        }
                        break;
                    case 'window':
                        ++(this.current_dicom as Dicom).current_brightness;
                        this.update_window();
                        this.request_render();
                        break;
                }
                break;
            }
            case 'ArrowDown': {
                switch ((<HTMLOptionElement>t.options[t.selectedIndex]).value) {
                    case 'pan':
                        this.current_dicom.current_view.pre_translate(0, 1);
                        this.request_frame();
                        break;
                    case 'zoom':
                        this.current_dicom.current_view.pre_scale_at(0.99, 0.99,
                            this.current_dicom.cols / 2, this.current_dicom.rows / 2);
                        this.request_frame();
                        break;
                    case 'rotate':
                        this.current_dicom.current_view.pre_rotate_at(-0.01,
                            this.current_dicom.cols / 2, this.current_dicom.rows / 2);
                        this.request_frame();
                        break;
                    case 'scroll':
                        const z = this.current_dicom.frames;
                        if (z > 0) {
                            let j = (this.current_dicom.current_index) + 1;
                            while (j >= z) {
                                j -= z;
                            }
                            if (j != this.current_dicom.current_index && this.current_dicom.data[j]) {
                                this.current_dicom.current_index = j;
                                this.update_scroll();
                                this.request_render();
                            }
                        }
                        break;
                    case 'window':
                        --(this.current_dicom as Dicom).current_brightness;
                        this.update_window();
                        this.request_render();
                        break;
                }
                break;
            }
            case 'ArrowLeft': {
                switch ((<HTMLOptionElement>t.options[t.selectedIndex]).value) {
                    case 'pan':
                        this.current_dicom.current_view.pre_translate(-1, 0);
                        this.request_frame();
                        break;
                    case 'zoom':
                        this.current_dicom.current_view.pre_scale_at(0.99, 0.99,
                            this.current_dicom.cols / 2, this.current_dicom.rows / 2);
                        this.request_frame();
                        break;
                    case 'rotate':
                        this.current_dicom.current_view.pre_rotate_at(-0.01,
                            this.current_dicom.cols / 2, this.current_dicom.rows / 2);
                        this.request_frame();
                        break;
                    case 'scroll':
                        const z = this.current_dicom.frames;
                        if (z > 0) {
                            let j = (this.current_dicom.current_index) + 1;
                            while (j >= z) {
                                j -= z;
                            }
                            if (j != this.current_dicom.current_index && this.current_dicom.data[j]) {
                                this.current_dicom.current_index = j;
                                this.update_scroll();
                                this.request_render();
                            }
                        }
                        break;
                    case 'window':
                        --(this.current_dicom as Dicom).current_contrast;
                        this.update_window();
                        this.request_render();
                        break;
                }
                break;
            }
            case 'ArrowRight': {
                switch ((<HTMLOptionElement>t.options[t.selectedIndex]).value) {
                    case 'pan':
                        this.current_dicom.current_view.pre_translate(1, 0);
                        this.request_frame();
                        break;
                    case 'zoom':
                        this.current_dicom.current_view.pre_scale_at(1.01, 1.01,
                            this.current_dicom.cols / 2, this.current_dicom.rows / 2);
                        this.request_frame();
                        break;
                    case 'rotate':
                        this.current_dicom.current_view.pre_rotate_at(0.01,
                            this.current_dicom.cols / 2, this.current_dicom.rows / 2);
                        this.request_frame();
                        break;
                    case 'scroll':
                        if (this.current_dicom.frames > 0) {
                            let j = (this.current_dicom.current_index) - 1;
                            while (j < 0) {
                                j += this.current_dicom.frames;
                            }
                            if (j != this.current_dicom.current_index && this.current_dicom.data[j]) {
                                this.current_dicom.current_index = j;
                                this.update_scroll();
                                this.request_render();
                            }
                        }
                        break;
                    case 'window':
                        ++(this.current_dicom as Dicom).current_contrast;
                        this.update_window();
                        this.request_render();
                        break;
                }
                break;
            }
        }
        event.preventDefault();
    };


    public readonly control_change_handler = (event? : Event) => {
        var t = this.ui.control as HTMLSelectElement;
        switch ((<HTMLOptionElement>t.options[t.selectedIndex]).value) {
            case 'pan': {
                this.left.onstart = this.pan_start;
                this.selected_control = t.selectedIndex;
                break;
            }
            case 'zoom': {
                this.left.onstart = this.zoom_start;
                this.selected_control = t.selectedIndex;
                break;
            }
            case 'rotate': {
                this.left.onstart = this.rotate_start;
                this.selected_control = t.selectedIndex;
                break;
            }
            case 'scroll': {
                this.left.onstart = this.scroll_start;
                this.selected_control = t.selectedIndex;
                break;
            }
            case 'window': {
                this.left.onstart = this.params_start;
                this.selected_control = t.selectedIndex;
                this.update_window();
                this.request_render();
                break;
            }
            case 'abdomen': {
                this.left.onstart = this.params_start;
                t.selectedIndex = find_option(t, 'window');
                (this.current_dicom as Dicom).current_brightness = 150;
                (this.current_dicom as Dicom).current_contrast = 500;
                this.update_window();
                this.request_render();
                break;
            }
            case 'pulmonary': {
                this.left.onstart = this.params_start;
                t.selectedIndex = find_option(t, 'window');
                (this.current_dicom as Dicom).current_brightness = -500;
                (this.current_dicom as Dicom).current_contrast = 1500;
                this.update_window();
                this.request_render();
                break;
            }
            case 'brain': {
                this.left.onstart = this.params_start;
                t.selectedIndex = find_option(t, 'window');
                (this.current_dicom as Dicom).current_brightness = 40;
                (this.current_dicom as Dicom).current_contrast = 80;
                this.update_window();
                this.request_render();
                break;
            }
            case 'bone': {
                this.left.onstart = this.params_start;
                t.selectedIndex = find_option(t, 'window');
                (this.current_dicom as Dicom).current_brightness = 570;
                (this.current_dicom as Dicom).current_contrast = 3000;
                this.update_window();
                this.request_render();
                break;
            }
            case 'reset': {
                t.selectedIndex = this.selected_control;
                this.reset_view();
                this.scale_to_fit();
                this.update_scroll();
                this.update_window();
                this.request_render();
                break;
            }
            case 'close': {
                if (!this.args.get_navigating || !this.args.get_navigating()) {
                    this.clear_controls(this.left);
                    this.clear_controls(this.right);
                    setImmediate(this.dispose_handler);
                } else {
                    t.selectedIndex = this.selected_control;
                }
                break;
            }
        }
        t.blur();
        (this.ui.canvas as HTMLCanvasElement).focus();
        if (event) {
            event.preventDefault();
        }
    };

    reset_view() {
        //this.clear_controls(this.left);
        //this.clear_controls(this.right);
        this.current_dicom.current_view.set_identity();
        if (this.current_dicom.dicom) {
            (this.current_dicom as Dicom).current_index = 0;
            (this.current_dicom as Dicom).current_contrast = (this.current_dicom as Dicom).window_width;
            (this.current_dicom as Dicom).current_brightness = (this.current_dicom as Dicom).window_center;
        }
    }

    //------------------------------------------------------------------------
    // Window Resizing
 
    calc_width() {
        if (this.fullscreen) {
            return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        } else {
            return this.parent.clientWidth - 1.6 * this.rem;
        }
    }

    calc_height() {
        if (this.fullscreen) {
            return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        } else {
            return this.size_reference.clientHeight -
                /*(this.ui.control_panel as HTMLElement).offsetHeight -
                (this.ui.title as HTMLElement).offsetHeight - */ 3.2 * this.rem; // - 24;
        }
    }

    calc_rect() {
        let width = this.calc_width()
        , height = this.calc_height()
        ;
        /*
        if (!this.fullscreen) {
            const rows = this.current_dicom.rows
            , cols = this.current_dicom.cols
            , scale = Math.min(width / cols, height / rows)
            ;
            width = cols * scale;
            height = rows * scale;
        }
        */

        return {'width': width, 'height': height};
    }

    scale_to_fit() {
        const width = this.calc_width()
        , height = this.calc_height()
        , rows = this.current_dicom.rows
        , cols = this.current_dicom.cols
        , scale = Math.min(width / cols, height / rows)
        ;

        this.fit_transform.set_identity();
        this.fit_transform.post_scale(scale, scale);
    }

    canvas_resize() {
        const r = this.calc_rect();

        (this.ui.canvas as HTMLElement).style.width = r.width + 'px';
        (this.ui.canvas as HTMLElement).style.height = r.height + 'px';
    }

    resize_canvas(force : boolean) : boolean {
        if (!this.current_dicom) {
            return;
        }

        const r = this.calc_rect();

        if (force ||
            (this.ui.canvas as HTMLElement).offsetWidth !== r.width ||
            (this.ui.canvas as HTMLElement).offsetHeight !== r.height
        ) {
            (this.ui.canvas as HTMLCanvasElement).width = r.width;
            (this.ui.canvas as HTMLElement).style.width = r.width + 'px';
            (this.ui.canvas as HTMLCanvasElement).height = r.height;
            (this.ui.canvas as HTMLElement).style.height = r.height + 'px';
            return true;
        }
        return false;
    }

    resize(force : boolean = false) : void {
        if (this.resize_canvas(force)) {
            this.scale_to_fit();
            this.frame();
        }
    }

    private scrollToImage() {           
        if (this.parent && this.parent.scrollHeight > this.parent.clientHeight) {
            this.parent.scrollTo(0, (this.ui.canvas_panel as HTMLCanvasElement).offsetTop);
        } else if (this.parent.parentElement && this.parent.parentElement.scrollHeight > this.parent.parentElement.clientHeight) {
            this.parent.parentElement.scrollTo(0, (this.ui.canvas_panel as HTMLCanvasElement).offsetTop - 1.6 * this.rem);
        }
    }

    public readonly window_resize_handler = (event : Event) => {
        this.resize();
    }

    set_dicom(dicom : Img) : Promise<void> {
        if (this.current_dicom) {
            if (this.current_dicom == dicom) {
                return Promise.resolve();
            } else {
                this.current_dicom.hide();
            }
        }
        this.current_dicom = dicom;
        //this.get_frames(this.current_dicom).then(() => {

        var t0 = performance.now(), t1 = t0, t2 = t0;
        return this.get_image_begin(this.current_dicom).then(() => {
            return this.get_image_frame(this.current_dicom, this.current_dicom.current_index);
        }).then((frame) => {
            this.current_dicom.data[this.current_dicom.current_index] = frame;
            t1 = performance.now();
            console.log('first frame load:', t1 - t0, 'ms');
            if (this.current_dicom.dicom && (this.current_dicom as Dicom).window_width <= 0) { // in case thumbnail not used.
                (this.current_dicom as Dicom).min_max();
                (this.current_dicom as Dicom).current_brightness = (this.current_dicom as Dicom).window_center;
                (this.current_dicom as Dicom).current_contrast = (this.current_dicom as Dicom).window_width;
            }
            if (!this.ui) {
                this.ui = make_elements(dicom_viewer_ui);
                this.parent.insertBefore(this.ui.canvas_panel, this.args.after.nextSibling);
                this.parent.insertBefore(this.ui.title, this.ui.canvas_panel);
                this.rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
                this.context = (this.ui.canvas as HTMLCanvasElement).getContext('2d');
                //this.context.imageSmoothingEnabled = false;
                //this.context.mozImageSmoothingEnabled = false;
                //this.context.webkitImageSmoothingEnabled = false;
                //this.context.msImageSmoothingEnabled = false;
                this.fit_transform = (new Mat3).set_identity();
                this.left = new Control('control-selected-left');
                this.right = new Control('control-selected-right');
                window.addEventListener('resize', this.window_resize_handler);
                window.addEventListener('mousemove', this.window_mousemove_handler);
                window.addEventListener('touchmove', this.window_touchmove_handler);
                window.addEventListener('mouseup', this.window_mouseup_handler);
                window.addEventListener('touchend', this.window_touchend_handler);
                window.addEventListener('contextmenu', this.canvas_contextmenu_handler);
                window.addEventListener('keydown', this.canvas_keydown_handler);
                this.ui.canvas.addEventListener('click', this.canvas_click_handler);
                this.ui.canvas.addEventListener('dblclick', this.canvas_dblclick_handler);
                this.ui.canvas.addEventListener('mousedown', this.canvas_mousedown_handler);
                this.ui.canvas.addEventListener('touchstart', this.canvas_touchstart_handler);
                this.ui.control.addEventListener('change', this.control_change_handler);
            }
            this.ui.title_text.nodeValue = this.current_dicom.caption;
            (this.ui.progress as HTMLElement).style.width = (100.0 / this.current_dicom.frames) + "%";
            const sel = this.ui.control as HTMLSelectElement;
            if (this.current_dicom.dicom && (this.current_dicom as Dicom).modality === 'CT') {
                (sel.options[find_option(sel, 'abdomen')] as HTMLOptionElement).hidden = false;
                (sel.options[find_option(sel, 'abdomen')] as HTMLOptionElement).disabled = false;
                (sel.options[find_option(sel, 'pulmonary')] as HTMLOptionElement).hidden = false;
                (sel.options[find_option(sel, 'pulmonary')] as HTMLOptionElement).disabled = false;
                (sel.options[find_option(sel, 'brain')] as HTMLOptionElement).hidden = false;
                (sel.options[find_option(sel, 'brain')] as HTMLOptionElement).disabled = false;
                (sel.options[find_option(sel, 'bone')] as HTMLOptionElement).hidden = false;
                (sel.options[find_option(sel, 'bone')] as HTMLOptionElement).disabled = false;
            } else {
                (sel.options[find_option(sel, 'abdomen')] as HTMLOptionElement).hidden = true;
                (sel.options[find_option(sel, 'abdomen')] as HTMLOptionElement).disabled = true;
                (sel.options[find_option(sel, 'pulmonary')] as HTMLOptionElement).hidden = true;
                (sel.options[find_option(sel, 'pulmonary')] as HTMLOptionElement).disabled = true;
                (sel.options[find_option(sel, 'brain')] as HTMLOptionElement).hidden = true;
                (sel.options[find_option(sel, 'brain')] as HTMLOptionElement).disabled = true;
                (sel.options[find_option(sel, 'bone')] as HTMLOptionElement).hidden = true;
                (sel.options[find_option(sel, 'bone')] as HTMLOptionElement).disabled = true;
            }
            this.update_window();
            if (this.current_dicom.frames > 1) {
                (sel.options[find_option(sel, 'scroll')] as HTMLOptionElement).hidden = false;
                (sel.options[find_option(sel, 'scroll')] as HTMLOptionElement).disabled = false;
            } else {
                (sel.options[find_option(sel, 'scroll')] as HTMLOptionElement).hidden = true;
                (sel.options[find_option(sel, 'scroll')] as HTMLOptionElement).disabled = true;
            }
            this.update_scroll();
            if (!this.selected_control) {
                if (this.current_dicom.frames > 1) {
                    this.left.onstart = this.scroll_start;
                    this.selected_control = find_option(sel, 'scroll');
                } else {
                    this.left.onstart = this.pan_start;
                    this.selected_control = find_option(sel, 'pan');
                }
            }
            sel.selectedIndex = this.selected_control;
            this.update_notes();
            this.canvas = document.createElement('canvas');
            this.canvas.width = this.current_dicom.cols;
            this.canvas.height = this.current_dicom.rows;
            this.canvas_resize();
            this.request_render();
            setImmediate(() => {
                this.resize(true);
                this.scrollToImage();
                (this.ui.canvas as HTMLCanvasElement).focus();
            });
            t2 = performance.now();
            this.current_dicom.data.length = this.current_dicom.frames;
            const j = this.current_dicom.current_index + 1;
            return fold(
                new RangeIterator(1, this.current_dicom.frames),
                Promise.resolve((j < this.current_dicom.frames) ? j : 0), (promise, cnt) => {
                    return promise.then((i) => {
                        //return sleep(1000).then(() => {
                        //    return i;
                        //});
                        //}).then((i) => {
                        return this.get_image_frame(this.current_dicom, i).then((frame : ArrayBuffer) => {
                            this.current_dicom.data[i] = frame;
                            (this.ui.progress as HTMLElement).style.width = (((cnt+1) * 100.0) / this.current_dicom.frames) + "%";
                            if (i++ === this.current_dicom.current_index) {
                                this.request_render();
                            }
                            return (i < this.current_dicom.frames) ? i : 0;
                        });
                    });
                }
            );
        }).then(() => {
            return this.get_image_end();
        }).then(() => {
            const t3 = performance.now();
            console.log('frames', this.current_dicom.current_index + 1, '..', this.current_dicom.frames, ':', t3 - t2, 'ms');
        }).then(() => {
            return wait(300);
        }).then(() => {
            (this.ui.progress as HTMLElement).style.width = '0%';
        });
    }

    // Clean up
   
    public readonly dispose_handler = () => {
        if (this.current_dicom) {
            this.current_dicom.hide();
            this.current_dicom = null;
        }
        this.context = null;
        this.canvas = null;
        if (this.left) {
            this.left.clear();
        }
        if (this.right) {
            this.right.clear();
        }
        this.parent = null;
        this.size_reference = null;
        this.fullscreen = false;
        if (this.ui) {
            remove_node(this.ui.canvas_panel);
            remove_node(this.ui.title);
            this.ui.canvas.removeEventListener('click', this.canvas_click_handler);
            this.ui.canvas.removeEventListener('dblclick', this.canvas_dblclick_handler);
            this.ui.canvas.removeEventListener('mousedown', this.canvas_mousedown_handler);
            this.ui.canvas.removeEventListener('touchstart', this.canvas_touchstart_handler);
            this.ui.control.removeEventListener('change', this.control_change_handler);
            this.ui = null;
        }
        window.removeEventListener('keydown', this.canvas_keydown_handler);
        window.removeEventListener('contextmenu', this.canvas_contextmenu_handler);
        window.removeEventListener('mousemove', this.window_mousemove_handler);
        window.removeEventListener('touchmove', this.window_touchmove_handler);
        window.removeEventListener('mouseup', this.window_mouseup_handler);
        window.removeEventListener('touchend', this.window_touchend_handler);
        window.removeEventListener('resize', this.window_resize_handler);
        this.args = null;

        if (typeof this.onclose === 'function') {
            this.onclose();
            this.onclose = null;
        }
    }
}

