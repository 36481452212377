//----------------------------------------------------------------------------
// Make Elements From JSON

function make_element(dom : {[index:string] : Node}, def : any) : Node {
    if (def.elem === 'text') {
        const elem : Node = document.createTextNode(def.text);
        //if (def.id) {
        //    elem.id = def.id;
        //}
        //if (def.className) {
        //    elem.className = def.className;
        //}
        if (def.parent) {
            dom[def.parent].appendChild(elem);
        }
        return elem;
    } else {
        const elem = document.createElement(def.elem);
        for (let a in def) {
            switch (a) {
                case 'elem':
                    break;
                case 'id':
                    elem.id = def.id;
                    break;
                case 'className':
                    elem.setAttribute('class', def.className);
                    break;
                case 'parent':
                    if (dom[def.parent] !== undefined) {
                        dom[def.parent].appendChild(elem);
                    }
                    break;
                case 'children':
                    for (let i = 0; i < def.children.length; ++i) {
                        elem.appendChild(make_element(dom, def.children[i]));
                    }
                    break;
                default:
                    elem.setAttribute(a, def[a]);
                    break;
            }
        }
        return elem;
    }
}

export function make_elements(def : any) : {[index:string] : Node} {
    let dom : {[index:string] : Node} = {};
    for (let x in def) {
        dom[x] = make_element(dom, def[x]);
    }
    return dom;
}

export function remove_children(node : Node) {
    while (node.firstChild) {
        node.removeChild(node.firstChild);
    }
}

export function remove_node(node : Node) {
    const parent = node.parentNode;
    if (parent) {
        parent.removeChild(node);
    }
}

export function fold<A, B>(i: Iterator<A>, acc: B, f: (x:B, y:A, j:number)=>B): B {
    let j = 0;
    while (true) {
        const x = i.next();
        if (x.done) {
            return acc;
        }
        acc = f(acc, x.value, j++);
    }
}

export function pfold<A, B>(i: Iterator<A>, f: (x:Promise<B>, y:A, j:number)=>Promise<B>): (v:B)=>Promise<B> {
    return (v : B) => {
        let acc = Promise.resolve(v);
        let j = 0;
        while (true) {
            const x = i.next();
            if (x.done) {
                return acc;
            }
            acc = f(acc, x.value, j++);
        }
    }
}

export class RangeIterator implements Iterator<number> {
    public next() : IteratorResult<number> {
        return (this.start < this.end) ?
            {value: this.start++, done: false} :
            {value: null, done: true};
    }

    constructor(private start: number, private end: number) {}
}

export function wait(n: number): Promise<void> {
    return new Promise(function(succ, fail) {
        setTimeout(succ, n);
    });
}

const poly = 0xedb88320;
export function crc32(s : string, crc : number) {
    crc = (~((crc === undefined) ? 0 : crc)) >>> 0;
    for (let i = 0; i < s.length; ++i) {
        crc ^= s.charCodeAt(i);
        for (let j = 15; j >= 0; --j) {
            crc = ((crc >>> 1) ^ ((-(crc & 1)) & poly)) >>> 0
        }
    }
    return (~crc) >>> 0;
}

const val32 = 0x100000000;
export function mul32u(a: number, b: number) {
    const ah = a >>> 16, al = a & 0xffff
    , bh = b >>> 16, bl = b & 0xffff
    , ahbl = ah * bl, albh = al * bh
    , l = (al * bl) + ((ahbl << 16) >>> 0) + ((albh << 16) >>> 0)
    , h = (ah * bh) + (ahbl >>> 16) + (albh >>> 16) + (l / val32)
    return new UInt64(h, l);
}

export class UInt64 {
    high : number;
    low : number;

    constructor(high: number, low: number) {
        this.high = high >>> 0;
        this.low = low >>> 0;
    }

    copy() : UInt64 {
        return new UInt64(this.high, this.low);
    }

    xor(x: UInt64) : UInt64 {
        this.high ^= x.high >>> 0;
        this.low ^= x.low >>> 0;
        return this;
    }

    add(x: UInt64) : UInt64 {
        this.low += x.low;
        this.high = (this.high + x.high + (this.low / val32)) >>> 0;
        this.low >>>= 0;
        return this;
    }

    mul(x: UInt64) : UInt64 {
        const m = mul32u(this.low, x.low)
            .add(mul32u(this.low, x.high).shl(32))
            .add(mul32u(this.high, x.low).shl(32));
        this.high = m.high;
        this.low = m.low;
        return this;
    }

    shl(n: number) : UInt64 {
        if (n < 32) {
            this.high = ((this.high << n) | (this.low >>> (32 - n))) >>> 0;
            this.low = (this.low << n) >>> 0;
        } else {
            this.high = (this.low << (n - 32)) >>> 0;
            this.low = 0;
        }
        return this;
    }

    shr(n: number) : UInt64 {
        if (n < 32) {
            this.low = ((this.low >>> n) | (this.high << (32 - n))) >>> 0;
            this.high = this.high >>> n;
        } else {
            this.low = this.high >>> (n - 32);
            this.high = 0;
        }
        return this;
    }

    or(n: number) : UInt64 {
        this.low |= n;
        return this;
    }
}

interface PRNG {
    range(min: number, max: number) : number;
}

const multiplier = new UInt64(0x5851f42d, 0x4c957f2d);
export class PCG32 implements PRNG {
    private state : UInt64;
    private inc : UInt64;

    constructor(initstate: UInt64, initseq: UInt64) {
        this.state = new UInt64(0, 0);
        this.inc = new UInt64(initseq.high, initseq.low).shl(1).or(1);
        this.next();
        this.state.add(initstate);
        this.next();
    }

    next() {
        const oldstate = this.state.copy();
        this.state.mul(multiplier).add(this.inc.or(1));
        const xorshifted = oldstate.copy().shr(18).xor(oldstate).shr(27).low;
        const rot = oldstate.shr(59).low;
        return ((xorshifted >>> rot) | (xorshifted << ((-rot) & 31))) >>> 0;
    }

    range(min: number, max:number) : number {
        min = Math.ceil(min);
        max = Math.floor(max);
        const d = max - min;
        const m = Math.floor(0xffffffff / d) * d;
        let r = this.next();
        while (r >= m) {
            r = this.next();
        }
        return (r % d) + min;
    }
}

function swap<A>(a: Array<A>, x: number, y: number) : void {
    if (x !== y) {
        const tmp = a[x];
        a[x] = a[y];
        a[y] = tmp;
    }
}

export function shuffle<A>(p : PRNG, a : Array<A>) : void {
    const n = a.length;
    for (let i = 0; i < (n - 1); ++i) {
        const j = p.range(i, n);
        swap(a, i, j);
    }
}
declare global {
    interface HTMLElement {
        webkitRequestFullscreen?: (elem: HTMLElement) => Promise<void>;
        mozRequestFullScreen?: (elem: HTMLElement) => Promise<void>;
        msRequestFullscreen?: (elem: HTMLElement) => Promise<void>;
    }
    interface Document {
        webkitExitFullscreen?: () => void;
        mozCancelFullScreen?: () => void;
        msExitFullscreen?: () => void;
    }
}
const root = document.documentElement;
export function getReqFullscreen() {
    return root.requestFullscreen || root.webkitRequestFullscreen || root.mozRequestFullScreen || root.msRequestFullscreen;
}
export function getExitFullscreen() {
    return document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen;
}

